<template>
  <div class="oilNewsletterContainer">
    <div class="icon">
      <p>{{ messageInfo.location }}</p>
    </div>
    <ul class="newsList">
      <li>
        <p class="oilProd">{{ messageArr[0] }}</p>
      </li>
      <li>
        <p class="oilEnergy">{{ messageArr[1] }}</p>
      </li>
      <li>
        <p class="oilFailRate">{{ messageArr[2] }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'OilNewsletter',
  props: {
    messageInfo: Object
  },
  data() {
    return {
      location: '全国',
      clearMessageTime: null,
      messageArr: [],
      messageIndex: 1,
      messageBegan: 0,
      messageEnd: 3
    }
  },
  methods: {
    // 生产简讯循环播放
    loopMessage() {
      this.messageArr = this.messageInfo.arr.slice(0, 3)
      this.clearMessageTime = setInterval(() => {
        if (this.messageIndex == 3) {
          this.messageIndex = 0
          this.messageBegan = 0
          this.messageEnd = 3
        }
        this.messageBegan = this.messageIndex * 3
        this.messageEnd = (this.messageIndex + 1) * 3
        this.messageArr = this.messageInfo.arr.slice(
          this.messageBegan,
          this.messageEnd
        )
        this.messageIndex++
      }, 1000 * 60 * 5)
    }
  },
  mounted() {
    this.loopMessage()
  },
  created() {},
  destroyed() {
    clearInterval(this.clearMessageTime)
  }
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.oilNewsletterContainer {
  margin-top: 14px;
  width: 380px;
  height: 136px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: rgba(23, 78, 128, 0.22);
  .icon {
    width: 144px;
    height: 93px;
    background: url('https://pump7niu.ahsanen.com/img/common/newsIcon.png');
    background-size: contain;
    p {
      width: 144px;
      height: 20px;
      text-align: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #01ffff;
    }
  }
  .newsList {
    width: auto;
    height: 136px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    li {
      min-width: 214px;
      height: 40px;
      border-bottom: 1px dashed rgba(201, 205, 214, 1);
      .oilProd,
      .oilEnergy,
      .oilFailRate {
        width: auto;
        height: 12px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 47px;
        &::before {
          margin: 1px 5px 0 0;
          content: '';
          display: inline-block;
          width: 7px;
          height: 7px;
          background: transparent;
          border-radius: 50%;
        }
      }
    }
    .oilProd {
      &::before {
        border: 3px solid #1ceff3;
      }
      span {
        color: #1ceff3;
      }
    }
    .oilEnergy {
      &::before {
        border: 3px solid #1e67f2;
      }
      span {
        color: #1e67f2;
      }
    }
    .oilFailRate {
      &::before {
        border: 3px solid #6e69f9;
      }
      color: #6e69f9;
    }
  }
}
</style>
