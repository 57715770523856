<template>
  <div class="machineAmountContainer">
    <oil-title>
      <span slot="left">设备统计</span>
      <span slot="right">单位：台</span>
    </oil-title>
    <div class="machineAmount">
      <div class="left">
        <div class="precent">
          <p>{{deviceInfo.onlineRate}}<span>%</span></p>
        </div>
        <div class="amount">设备总数：{{deviceInfo.total}}</div>
      </div>
      <div class="right">
        <div class="online">
          <p class="text">在线设备</p>
          <p class="num">{{deviceInfo.onlineTotal}}</p>
        </div>
        <div class="offline">
          <p class="text">离线设备</p>
          <p class="num">{{deviceInfo.offLineTotal}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OilTitle from '../oilTitle/OilTitle.vue'
export default {
  name: 'MachineAmount',
  props: {
    deviceInfo: Object
  },
  components: { OilTitle },
  data: () => {
    return {}
  },
  methods: {},
  mounted() {
  },
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.machineAmountContainer {
  margin-top: 16px;
  width: 380px;
  height: auto;
  .machineAmount {
    margin-top: 14px;
    width: 380px;
    height: 222px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: rgba(23, 78, 128, 0.22);
    .left {
      margin-left: 21px;
      min-width: 146px;
      height: auto;
      text-align: center;
      .precent {
        position: relative;
        width: 146px;
        height: 113px;
        background: url('https://pump7niu.ahsanen.com/img/common/mAmount-bg.png');
        background-size: cover;
        p {
          position: absolute;
          left: 30%;
          top: 30px;
          width: 57px;
          height: 28px;
          letter-spacing: 2px;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 800;
          color: #00fbfa;
          span {
            font-size: 20px;
          }
        }
      }
      .amount {
        margin-top: 19px;
        width: auto;
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #88b1d0;
      }
    }
    .right {
      margin-right: 29px;
      min-width: 110px;
      height: auto;
      .online,
      .offline {
        min-width: 110px;
        height: auto;
        .text {
          width: auto;
          height: 15px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .num {
          margin-top: 11px;
          width: auto;
          height: 36px;
          font-size: 36px;
          font-family: SourceHanSansCN-Bold;
          font-weight: 500;
          font-style: italic;
          color: #ffffff;
        }
      }
      .online {
        .text::before {
          content: '';
          display: inline-block;
          margin-right: 15px;
          width: 13px;
          height: 13px;
          background: #27e54a;
          border-radius: 50%;
        }
      }
      .offline {
        margin-top: 50px;
        .text::before {
          content: '';
          display: inline-block;
          margin-right: 15px;
          width: 13px;
          height: 13px;
          background: #a9a9a9;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
