<template>
  <div class="container">
    <div class="btnList">
      <button
        :class="getBtnClassName(index)"
        :disabled="activeIndex == index"
        v-for="(item, index) in btnList"
        :key="index"
        @click="handlePerButton(index)"
      >
        {{ item }}
      </button>
    </div>
    <div class="totalOilProdChart" ref="totalOilProdChart"></div>
  </div>
</template>

<script>
export default {
  name: 'TotalOilProdChart',
  props: {
    deviceInfo: Object
  },
  data: () => {
    return {
      activeIndex: 0,
      btnList: ['年', '月', '日'],
      chartInstance: null,
      deviceDataInfo: {}
    }
  },
  watch: {
    deviceInfo() {
      this.handlePerButton(0)
    }
  },
  methods: {
    /**
     * 事件监听
     */
    // 年月日切换
    handlePerButton(index) {
      this.activeIndex = index
      if (index == 0) {
        this.deviceDataInfo = this.deviceInfo.yearInfo
      } else if (index == 1) {
        this.deviceDataInfo = this.deviceInfo.monthInfo
      } else {
        this.deviceDataInfo = this.deviceInfo.dayInfo
      }
      this.updateChart()
    },
    // 初始化echartInstance对象
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.totalOilProdChart)
      this.chartShowLoading()
      this.updateChart()
    },
    // 更新图表
    updateChart() {
      const option = {
        tooltip: {
          trigger: 'item'
        },

        color: [
          // 环形颜色
          '#16F9FC',
          '#1CA6F8',
          '#6E69F9',
          '#1764EA'
        ],
        graphic: [
          {
            type: 'text',
            left: '19%',
            top: '40%',
            style: {
              text: this.deviceDataInfo.total_production + '\r\n总计采油量',
              textAlign: 'center',
              fontSize: 16,
              fontFamily: 'Source Han Sans CN',
              fill: '#FFFFFF',
              lineHeight: 30
            }
          },
          {
            type: 'text',
            right: '20%',
            top: '40%',
            style: {
              text: this.deviceDataInfo.total_power + '\r\n总计能耗',
              textAlign: 'center',
              fontSize: 16,
              fontFamily: 'Source Han Sans CN',
              fill: '#FFFFFF',
              lineHeight: 30
            }
          }
        ],
        series: [
          {
            type: 'pie',
            radius: ['40%', '58.5%'],
            center: ['50%', '25%'],
            avoidLabelOverlap: true,
            label: {
              // 文本标签
              show: false,
              position: 'inner',
              normal: {
                textStyle: {
                  fontSize: 14,
                  lineHeight: 20
                },
                formatter: (v) => {
                  return (
                    '{name|' + v.name + '}' + '\n\r' + '{value|' + v.value + '}'
                  )
                },
                rich: {
                  name: {
                    fontSize: 14,
                    color: '#9BC8DD'
                  },
                  value: {
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: '#fff'
                  }
                }
              }
            },
            data: this.deviceDataInfo.T_Production
          },
          {
            type: 'pie',
            radius: ['40%', '58.5%'],
            center: ['50%', '75%'],
            avoidLabelOverlap: true,
            label: {
              // 文本标签
              show: false,
              position: 'inner',
              normal: {
                textStyle: {
                  fontSize: 14,
                  lineHeight: 20
                },
                formatter: (v) => {
                  return (
                    '{name|' + v.name + '}' + '\n\r' + '{value|' + v.value + '}'
                  )
                },
                rich: {
                  name: {
                    fontSize: 14,
                    color: '#9BC8DD'
                  },
                  value: {
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: '#fff'
                  }
                }
              }
            },
            data: this.deviceDataInfo.T_PowerC
          }
        ],
        // Optional. Only for responsive layout:
        media: [
          {
            query: { minAspectRatio: 1 },
            option: {
              series: [{ center: ['25%', '50%'] }, { center: ['75%', '50%'] }]
            }
          },
          {
            option: {
              series: [{ center: ['50%', '25%'] }, { center: ['50%', '75%'] }]
            }
          }
        ]
      }
      this.chartInstance.hideLoading()
      this.chartInstance.setOption(option)
    },
    /**
     * 网络请求
     */

    /**
     * 其它方法
     */
    // 图表showLoading
    chartShowLoading() {
      this.chartInstance.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
    },
    // 图表自适应
    chartResize() {
      window.onresize = () => {
        this.$echarts
          .init(document.getElementsByClassName('currentOilProdChart'))
          .resize()
      }
    },
    getBtnClassName(index) {
      return this.activeIndex == index ? 'activeButton' : 'inactiveButton'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.deviceDataInfo = this.deviceInfo.yearInfo
      this.initChart()
    })
  },
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.activeButton {
  background: linear-gradient(98deg, #00c0ff 0%, #0675fd 100%);
}
.inactiveButton {
  background: RGBA(1, 67, 109, 1);
}

.container {
  margin-top: 11px;
  width: 656px;
  height: 303px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: rgba(23, 78, 128, 0.22);
  .btnList {
    margin: 13px 20px 0 0;
    width: auto;
    height: 20px;
    button {
      margin-right: 7px;
      width: 53px;
      height: 20px;
      line-height: 12px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      border: 1px solid #00c0ff;
      border-radius: 2px;
      cursor: pointer;
    }
  }
  .totalOilProdChart {
    width: 656px;
    height: 270px;
  }
}
</style>
